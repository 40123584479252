import _kindOf from "kind-of";
var exports = {};
var typeOf = _kindOf;

exports = function toPath(args) {
  if (typeOf(args) !== "arguments") {
    args = arguments;
  }

  return filter(args).join(".");
};

function filter(arr) {
  var len = arr.length;
  var idx = -1;
  var res = [];

  while (++idx < len) {
    var ele = arr[idx];

    if (typeOf(ele) === "arguments" || Array.isArray(ele)) {
      res.push.apply(res, filter(ele));
    } else if (typeof ele === "string") {
      res.push(ele);
    }
  }

  return res;
}

export default exports;